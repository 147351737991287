<div id="headline" class="headline">
  <img class="rocket" src="assets/images/rocket.gif">
  <div [id]="id"></div>
</div>
<div id="team" class="box">
  <div class="container">
    <h1 class="text-center headlines">DAS TEAM</h1>
    <div class="persons d-flex justify-content-evenly flex-wrap">
      <div class="peron d-flex column align-items-center mt-2 clickable mr-4" (click)="navigateToLn('MR')">
        <img src="assets/images/mr.jpg" style="width: 200px; border-radius: 50%">
        <div class="d-flex align-items-center mt-4 justify-content-center">
          <div class="name mr-4 ">Matthias Remy</div>
          <img src="assets/images/ln.png" class="ml-4"
               style="width: 30px; height: 30px">
        </div>
      </div>
      <div class="peron d-flex column align-items-center mt-2 clickable ml-4" (click)="navigateToLn('TS')">
        <img src="assets/images/ts.jpg" style="width: 200px; border-radius: 50%">
        <div class="d-flex align-items-center mt-4 justify-content-center">
          <div class="name mr-4">Tobias Simon</div>
          <img src="assets/images/ln.png"
               style="width: 30px; height: 30px;">
        </div>
      </div>
    </div>
  </div>
</div>

<div id="service" class="box">

  <div class="wave top" >
    <svg viewBox="0 0 500 150" preserveAspectRatio="none" style="height: 100%; width: 100%;">
    <path d="M-20.32,56.53 C246.61,-68.78 299.66,209.50 576.74,-23.40 L500.00,150.00 L0.00,150.00 Z" style="stroke: none; fill: #EAF0F5;"></path>
    </svg>
  </div>
  <div class="content">
    <div class="d-flex justify-content-evenly flex-wrap">
      <div class="service-first">
        WIR LIEBEN DAS CODEN, COOLE TYPEN UND INNOVATIVE IDEEN...
      </div>
      <div class="service-second">
        ...bei der technischen Umsetzung dieser
        helfen wir euch gerne und setzen hierfür
        modernste Technologien ein.
      </div>
    </div>
    <div class="projects">
      <h1 class="text-center mb-0">UNSERE BISHERIGEN PROJEKTE</h1>
      <div class="d-flex justify-content-evenly box flex-wrap">
        <div style="width: 144px" class="reference mr-4 ml-4 clickable" (click)="openModal('QN')">
          <div class="img-box">
            <img src="assets/images/quiznet.png">
          </div>
          <div>
            <a>Quiz.net</a>
            <div style="font-size: 13px" class="mt-1">CO-Founder & CTO</div>
          </div>
        </div>
        <div style="width: 248px" class="reference ml-4 mr-4 clickable" (click)="openModal('SC')">
          <div class="img-box">
            <img src="assets/images/sportscurator.png">
          </div>
          <div>
            <a>Sportscurator</a>
            <div style="font-size: 13px" class="mt-1">CO-Founder & CTO</div>
          </div>
        </div>
        <div style="width: 250px" class="reference mr-4 ml-4 clickable" (click)="openModal('IN')">
          <div class="img-box">
            <img src="assets/images/influnate.png">
          </div>
          <div>
            <a>Influnate</a>
            <div style="font-size: 13px" class="mt-1">Senior Software Developer</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="wave bottom" >
    <svg viewBox="0 0 500 150" preserveAspectRatio="none" style="height: 100%; width: 100%;">
      <path d="M-50.23,124.63 C200.33,-72.73 290.63,226.27 575.61,19.03 L500.00,0.00 L0.00,0.00 Z" style="stroke: none; fill: #EAF0F5;"></path>
    </svg>
  </div>
</div>

<div id="contact" class="box">
  <div class="container d-flex column align-items-center">
    <h1 class="text-center headlines">WIR FREUEN UNS VON EUCH ZU HÖREN</h1>
    <a class="email" style="color: #355881" href="mailto:techinject.business@gmail.com"><b>Kontakt</b></a>
  </div>

</div>


<app-modal #scModal [backgroundCloseable]="true" [content]="scContent">
  <ng-template #scContent>
    <div class="modal-header">
      <div class="sc-dashboard dashboard"></div>
      <div class="sc-app app"></div>
    </div>
    <div class="modal-content">
      <div class="d-flex justify-content-center pt-4">
        <img width="200" src="assets/images/sportscurator.png" />
      </div>
      <div class="p-4">
        <div class="time mb-4 text-center">2020 - Heute</div>
        <div class="text">
          Bei Sportscurator handelt es sich um eine White-Label Fitness App mit einem integrierten Ernährungsplan für Personal
          Trainer und Fitness-Influencer.Die Trainer können sich auf Sportscurator anmelden und in wenigen Schritten ihre
          eigen Fitness App erstellen.Dabei bietet Sportscurator schon eine große Auswahl an Fitnessübungen an die von den
          Trainern personalisiert werden können. Die Trainer können dann die App über einen zuvor definierten monatlichen
          Preis an ihre Endkunden vertreiben.
          <br>
          <br>
          Wir haben die komplette technische Infrastruktur aufgebaut eigene Ideen mit eingebracht und sind für die Weiterentwicklung
          verantwortlich.
          <br>
          <br>
          <div class="w-100 text-center clickable text-underlined" (click)="goToLink('https://sportscurator.de')">
            https://sportscurator.de
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</app-modal>

<app-modal #qnModal [backgroundCloseable]="true" [content]="qnContent">
  <ng-template #qnContent>
    <!--<div class="modal-header">
      <div class="inf-dashboard dashboard"></div>
      <div class="inf-cd app"></div>
    </div>-->
    <div class="modal-content">
      <div class="d-flex justify-content-center pt-4">
        <img width="200" src="assets/images/quiznet.png" />
      </div>
      <div class="p-4">
        <div class="time mt-2 mb-4 text-center">2021 - Heute</div>
        <div class="text">
          QuizNet organisiert Online Quiz Shows mit persönlichem Quizmaster, Videochat und vielen spannenden
          Fragen.
          <br>
          <br>
          Wir unterstützen bei der digitalisierung von Prozessen und bauen eine technische skalierbare Infrastruktur auf.
          <br>
          <br>
          <div class="w-100 text-center clickable text-underlined" (click)="goToLink('https://quiznet.online')">
            https://quiznet.online
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</app-modal>

<app-modal #inModal [backgroundCloseable]="true" [content]="inContent">
  <ng-template #inContent>
    <div class="modal-header">
      <div class="inf-dashboard dashboard"></div>
      <div class="inf-cd app"></div>
    </div>
    <div class="modal-content">
      <div class="d-flex justify-content-center pt-4">
        <img width="200" src="assets/images/influnate.png" />
      </div>
      <div class="p-4">
        <div class="time mt-2 mb-4 text-center">2019 - 2021</div>
        <div class="text">
          Influnate hat gestartet als kommentar basierter Payment-Provider. Man konnte über kommentare mit einer
          einfachen Syntax bei Instagram Twitter oder Reddit Geld an die Ersteller der jeweiligen Posts senden. Mit der
          Zeit hat sich Influnate dann zu einem Streaming donation Provider entwickelt. Hierbei wurde der USP der Syntax
          basierten donations mit aufgenommen und man kann mit der Syntax über den Twitch-Chat seinem jeweiligen Streamer
          eine donation zukommen lassen.
          <br>
          <br>
          Hier haben wir die komplette Infrastruktur aufgebaut und bis zu unserem Aussscheiden Ende 2021 weiterentwickelt.
          <br>
          <br>
          <div class="w-100 text-center clickable text-underlined" (click)="goToLink('https://influnate.com')">
            https://influnate.com
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</app-modal>


<!--<div class="content pt-4" style="padding-bottom: 3rem">
  <div class="container d-flex column">
    <app-chat-box [type]="'PERSON'" class="mt-4 mb-4 d-flex" >
      <div class="h1">Wer ist Techinject?</div>
    </app-chat-box>

    <app-chat-box [type]="'TECHINJECT'" class="mt-4 mb-4 d-flex justify-content-end">
      <div>
        <div class="d-flex align-items-center mt-2 clickable" (click)="navigateToLn('MR')">
          <img src="assets/images/mr.png" class="mr-4" style="width: 90px">
          <div class="name mr-4 ml-4">Matthias Remy</div>
          <img src="assets/images/ln.png" class="ml-4"
               style="width: 30px; height: 30px">
        </div>
        <div class="d-flex align-items-center mt-2 clickable" (click)="navigateToLn('TS')">
          <img src="assets/images/ts.png" class="mr-4" style="width: 90px">
          <div class="name ml-4">Tobias Simon</div>
          <img src="assets/images/ln.png"
               style="width: 30px; height: 30px; margin-left: auto">
        </div>
        <div class="d-flex"></div>
      </div>
    </app-chat-box>

    <app-chat-box [type]="'PERSON'" class="mt-4 mb-4 d-flex" >
      <div class="h1">Okay und was macht Techinject?</div>
    </app-chat-box>

    <app-chat-box [type]="'TECHINJECT'" class="mt-4 mb-4 d-flex justify-content-end">
      <div class="text">
        Wir helfen euch als <b>CTO</b> und oder <b>Software-Entwickler</b> oder auch nur in <b>Beratender Tätigkeit</b> eure Projekte
        mit modernesten Technologien erfolgreich umzusetzten. Da unsere <b>Leidenschaft</b> im <b>Startup Umfeld</b>
        liegt, haben wir auch dort unseren Fokus.
      </div>
    </app-chat-box>

    <app-chat-box [type]="'PERSON'" class="mt-4 mb-4 d-flex" >
      <div class="h1">Genau das was wir brauchen. Habt ihr Referenzen?</div>
    </app-chat-box>

    <app-chat-box [type]="'TECHINJECT'" class="mt-4 mb-4 d-flex justify-content-end">
      <div class="d-flex">
        <div class="reference mr-4 clickable" (click)="goToLink('https://sportscurator.de')">
          <img src="https://media-exp1.licdn.com/dms/image/C4D0BAQHbVt-uq1ajUA/company-logo_200_200/0/1541347232129?e=1631145600&v=beta&t=O0bkK5Xzhyu5LxBHJfTUPz2EsrWaE-AAXhPm0Uw775g">
          <div>
            <a>Sportscurator</a>
            <div style="font-size: 13px" class="mt-1">CO-Founder & CTO</div>
          </div>
        </div>
        <div class="reference mr-4 ml-4 clickable" (click)="goToLink('https://influnate.com')">
          <img src="https://media-exp1.licdn.com/dms/image/C4D0BAQETHFGJx2ms4A/company-logo_200_200/0/1584365010056?e=1631145600&v=beta&t=B9gz-hyedDHcnwipjEDs1FLARaCx2Oj8VnLWXgw-Fhc">
          <div>
            <a>Influnate</a>
            <div style="font-size: 13px" class="mt-1">CTO</div>
          </div>
        </div>
      </div>
    </app-chat-box>

    <app-chat-box [type]="'PERSON'" class="mt-4 mb-4 d-flex" >
      <div class="h1">Welche Technologien setzt ihr ein?</div>
    </app-chat-box>

    <app-chat-box [type]="'TECHINJECT'" class="mt-4 mb-4 d-flex justify-content-end">
      <div class="text">
        Wir schauen immer <b>individuell</b> welche Technologien sich für ein <b>Projekt</b> am besten eignen. Um eine hohe <b>Beschleunigung</b>
        in den Projekten zu erreichen und <b>Flexibel auf neue Kundenanforderungen</b> reagieren zu können, setzten wir aber oft auf einen <b>Serverless/AWS</b> tech stack.
        Hier findet ihr eine kleine Zusammenstellung mit was wir schon gearbeitet haben:
      </div>
      <br />
      <br />
      <div class="d-flex justify-content-around tech  flex-wrap">
        <img (click)="goToLink('https://serverless.com')"
             src="https://s3-us-west-2.amazonaws.com/assets.site.serverless.com/logos/serverless-square-icon-text.png" height="50">
        <img (click)="goToLink('https://aws.amazon.com')"
             src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/AWS_Simple_Icons_AWS_Cloud.svg/1024px-AWS_Simple_Icons_AWS_Cloud.svg.png" height="50">
        <img (click)="goToLink('https://angular.io')"
             src="https://angular.io/assets/images/logos/angular/logo-nav@2x.png" height="50">
        <img (click)="goToLink('https://reactjs.org')"
             src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xMS41IC0xMC4yMzE3NCAyMyAyMC40NjM0OCI+CiAgPHRpdGxlPlJlYWN0IExvZ288L3RpdGxlPgogIDxjaXJjbGUgY3g9IjAiIGN5PSIwIiByPSIyLjA1IiBmaWxsPSIjNjFkYWZiIi8+CiAgPGcgc3Ryb2tlPSIjNjFkYWZiIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIi8+CiAgICA8ZWxsaXBzZSByeD0iMTEiIHJ5PSI0LjIiIHRyYW5zZm9ybT0icm90YXRlKDYwKSIvPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgPC9nPgo8L3N2Zz4K" height="50">
        <img (click)="goToLink('https://developer.apple.com')"
             src="https://img.icons8.com/ios/452/ios-logo.png" height="50">
        <img (click)="goToLink('https://developer.android.com')"
             src="https://cdn.worldvectorlogo.com/logos/android.svg" height="50" >
      </div>
    </app-chat-box>

    <app-chat-box [type]="'PERSON'" class="mt-4 mb-4 d-flex" >
      <div class="h1">Wieviel kostet denn der Spaß? 😅</div>
    </app-chat-box>

    <app-chat-box [type]="'TECHINJECT'" class="mt-4 mb-4 d-flex justify-content-end">
      <div class="text">
        Auch hier schauen wir <b>individuell</b> in welchem <b>Stadium</b> sich ein Projekt befindet und sind dann für verschiedene
        Bezahlmodelle offen. Bei <b>Langfristigen Projekten</b> arbeiten wir in der Regel <b>erstmal unentgledlich</b> um zu schauen, ob man auf <b>menschlicher Ebene</b>
        gut zusammenarbeitet.
        <br />
        Keine Angst, da wird man sich schon einig! 😊
      </div>
    </app-chat-box>

    <app-chat-box [type]="'PERSON'" class="mt-4 mb-4 d-flex" >
      <div class="h1">Klingt super! Wie erreicht man euch?</div>
    </app-chat-box>

    <app-chat-box [type]="'TECHINJECT'" class="mt-4 mb-4 d-flex justify-content-end">
      <div>
        Schreibt uns einfach eine E-Mail an
        <br />
        <br />
        <a class="email" style="color: #355881" href="mailto:techinject.business@gmail.com"><b>techinject.business@gmail.com</b></a>
        <br />
        <br />
        Wir freuen uns von euch zu hören! 🎉
      </div>
    </app-chat-box>

    <app-chat-box [type]="'PERSON'" class="mt-4 mb-4 d-flex" >
      <div class="h1">Top, danke 😀</div>
    </app-chat-box>
  </div>
</div>-->
