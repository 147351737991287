import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-disclosure',
  templateUrl: './disclosure.component.html',
  styleUrls: ['./disclosure.component.scss']
})
export class DisclosureComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
