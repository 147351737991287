<ng-container *ngIf="isOpened | async">
  <div class="influnate-modal" [style.width.px]="width">
    <div class="d-flex column justify-content-center">
      <div class="influnate-modal-dialog">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </div>
    </div>
    <div *ngIf="backgroundCloseable" class="influnate-modal-background" (click)="close()"></div>
    <div *ngIf="!backgroundCloseable" class="influnate-modal-background"></div>
  </div>
</ng-container>
