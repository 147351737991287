import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChatBoxComponent } from './components/chat-box/chat-box.component';
import { HomeComponent } from './pages/home/home.component';
import { DisclosureComponent } from './pages/disclosure/disclosure.component';
import {NgParticlesModule} from "ng-particles";
import {ModalComponent} from "./components/modal/modal.component";

@NgModule({
  declarations: [
    AppComponent,
    ChatBoxComponent,
    HomeComponent,
    DisclosureComponent,
    ModalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgParticlesModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
