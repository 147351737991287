import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { BehaviorSubject, empty } from 'rxjs';
import {flatMap, takeWhile} from "rxjs/operators";

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  @Input()
  mdlType: ModalType;

  @Input()
  iconType: string;

  @Input()
  backgroundCloseable = true;

  @Input()
  content: TemplateRef<any>;

  private isOpenedSubject = new BehaviorSubject(false);
  readonly isOpened = this.isOpenedSubject.asObservable();

  constructor() { }

  ngOnInit(): void {
  }

  open(): Promise<void> {
    this.isOpenedSubject.next(true);
    return this.isOpened.pipe(takeWhile(isOpened => isOpened === true))
      .pipe(flatMap(() => empty()))
      .toPromise();
  }

  close(): void {
    this.isOpenedSubject.next(false);
  }

  get modalType() {
    switch (this.mdlType) {
      case 'INFO':
        return 'info-modal';
      case 'ALERT':
        return 'alert-modal';
      case 'BASE':
        return 'base-modal';
      default:
        return 'ase-modal';
    }
  }
}

export type ModalType = 'INFO' | 'ALERT' | 'BASE';
