import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import { HostListener } from "@angular/core";
import {IParticlesProps, NgParticlesComponent} from "ng-particles";
import { tsParticles } from 'tsparticles';
import {ModalComponent} from "../../components/modal/modal.component";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements AfterViewInit {

  id = "tsparticles";

  screenWidth;

  @ViewChild('inModal')
  inModal: ModalComponent

  @ViewChild('scModal')
  scModal: ModalComponent

  @ViewChild('qnModal')
  qnModal: ModalComponent

  particlesOptions: IParticlesProps = {
    background: {
      color: {
        value: "#355881"
      }
    },
    fpsLimit: 60,
    interactivity: {
      events: {
        onHover: {
          enable: true,
          mode: "grab"
        },
      },
      modes: {
        bubble: {
          distance: 400,
          duration: 2,
          opacity: 0.8,
          size: 3
        },
        push: {
          quantity: 4
        },
        repulse: {
          distance: 200,
          duration: 0.4
        }
      }
    },
    particles: {
      color: {
        value: "#ffffff"
      },
      links: {
        color: "#ffffff",
        distance: 150,
        enable: true,
        opacity: 0.5,
        width: 1
      },
      collisions: {
        enable: true
      },
      move: {
        direction: "none",
        enable: true,
        outMode: "bounce",
        random: false,
        speed: 1,
        straight: false
      },
      number: {
        density: {
          enable: false,
          value_area: 800
        },
        value: 100
      },
      opacity: {
        value: 0.5
      },
      shape: {
        type: "circle"
      },
      size: {
        value: 3
      }
    },
    detectRetina: true
  };

  constructor() {
  }

  async ngAfterViewInit() {
    await this.onResize()
  }

  @HostListener('window:resize', ['$event'])
  async onResize(event?) {
    this.screenWidth = window.innerWidth;
    console.log("Width: ", this.screenWidth)
    this.particlesOptions.particles.number.value = this.screenWidth / 15;
    this.particlesOptions.particles.move.enable = this.screenWidth > 500;
    this.particlesOptions.interactivity.events.onHover.enable = this.screenWidth > 500;

    await tsParticles.load(this.id, this.particlesOptions)

  }

  navigateToLn(to: 'TS' | 'MR') {
    to === 'MR' ? window.open(`https://www.linkedin.com/in/matthias-remy/`, '_blank') :
      window.open(`https://www.linkedin.com/in/tobias-simon-05979b17a/`, '_blank');
  }

  async openModal(type: 'SC' | 'QN' | 'IN') {
    switch (type) {
      case "IN":
        if(this.screenWidth >= 740) {
          await this.inModal.open();
        } else {
          this.goToLink('https://influnate.com')
        }
        break;
      case "SC":
        if(this.screenWidth >= 740) {
          await this.scModal.open();
        } else {
          this.goToLink('https://sportscurator.de')
        }
        break;
      case "QN":
        if(this.screenWidth >= 740) {
          await this.qnModal.open();
        } else {
          this.goToLink('https://quiznet.online')
        }
        break;

    }
  }

  goToLink(link: string) {
    window.open(link, '_blank');
  }

}
