<div class="header">
  <div class="container d-flex justify-content-around align-items-center">
    <a class="navItem clickable" href="#headline">Home</a>
    <a class="navItem clickable" href="#team">Team</a>
    <img src="assets/images/logo_small.png" style="height: 60px; ">
    <a class="navItem clickable" href="#service">Service</a>
    <a class="navItem clickable" href="#contact">Kontakt</a>
  </div>
</div>

<router-outlet></router-outlet>

<div class="footer">
  <div class="container d-flex column align-items-center justify-content-center" style="height: 100%">
    <a routerLink="/disclosure" style="color: white">IMPRESSUM</a>
    <br />
    <br />
    <p style="color: white">Made with  ❤️ from Techinject</p>
  </div>
</div>
